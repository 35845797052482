import { Router } from '@angular/router';
import { BusinessRelationshipDataService } from '../services/business-relationship-data.service';
import { inject } from '@angular/core';
import { filter, tap } from 'rxjs';
import { BusinessRelationship } from '../api-client.generated';
import { SecondaryRoute } from '../model/secondary-route.model';

export const businessRelationshipGuard = (route: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [x: string]: any };
  routeConfig: { path: string | undefined };
}) => {
  const router: Router = inject(Router);
  const service: BusinessRelationshipDataService = inject(BusinessRelationshipDataService);

  service.activeRelationship$
    .pipe(
      filter((activeRelationship: BusinessRelationship) => activeRelationship.id !== ''),
      tap((activeRelationship: BusinessRelationship) => {
        const secondaryRoutes: SecondaryRoute[] = route.data['children'];
        const availableRoutes: SecondaryRoute[] = [];
        secondaryRoutes.forEach((secondaryRoute: SecondaryRoute) => {
          if (
            !(
              (secondaryRoute.requiresCustomer && !activeRelationship?.isCustomer) ||
              (secondaryRoute.requiresTrader && !activeRelationship?.isTrader)
            )
          ) {
            availableRoutes.push(secondaryRoute);
          }
        });

        const paths: (string | undefined)[] = availableRoutes.map((route: SecondaryRoute) => route.path);

        if (!paths.includes(route.routeConfig?.path)) {
          if (availableRoutes.length > 0) {
            router.navigate([route.data['id'], availableRoutes[0]?.path]);
          } else {
            router.navigate(['user-information']);
          }
        }
      })
    )
    .subscribe();
};
