<div class="czone-main-spacing">
  <h1 class="title">{{ 'privacyPolicy.title' | transloco }}</h1>

  <div class="privacy-policy-container">
    <ng-container *ngIf="localizationService.selectedLanguage$ | async as selectedLanguage">
      <ng-container *ngIf="selectedLanguage === 'cs'; else en">
        <czone-privacy-policy-cs></czone-privacy-policy-cs>
      </ng-container>
      <ng-template #en>
        <czone-privacy-policy-en></czone-privacy-policy-en>
      </ng-template>
    </ng-container>
    <button mat-raised-button color="primary" (click)="openGdprRequest()">
      {{ 'privacyPolicy.fileRequest' | transloco }}
    </button>
  </div>
</div>
