import { Component, Inject } from '@angular/core';
import { AppConfig } from '@client-zone/data-access';
import { LocalizationService } from '@client-zone/utils';
import { APP_CONFIG } from 'src/app/app.component';

@Component({
  selector: 'czone-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
  constructor(public localizationService: LocalizationService, @Inject(APP_CONFIG) public appConfig?: AppConfig) {}

  openGdprRequest(): void {
    window.open(this.appConfig?.storageUrl + '/documents/zadost_GDPR_UCED.pdf', '_blank');
  }
}
